import { template as template_e62d8469ecf84bc8b7d9a1c27c339bef } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_e62d8469ecf84bc8b7d9a1c27c339bef(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;

import { template as template_d3b5353066b24ed0913c8b4b29357639 } from "@ember/template-compiler";
const FKLabel = template_d3b5353066b24ed0913c8b4b29357639(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
